<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiVideoProps {
  contentType?: string | undefined;
  fields: any;
  index?: number;
  language?: string | undefined;
  slug?: string | undefined;
}
const props = withDefaults(defineProps<CiVideoProps>(), {
  contentType: undefined,
  index: 0,
  language: undefined,
  slug: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { trackAdImpression, impressionId } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const contexts = computed(() => {
  return [
    {
      schema: 'iglu:com.camparound/campinginfo_cs_video/jsonschema/1-0-0',
      data: {
        headline: props.fields.headline,
        video_description: props.fields.video_description,
        video_host: props.fields.video_host,
        video_id: props.fields.video_id,
        order: props.index + 1,
      },
    },
  ];
});

/********************
 * FUNCTIONS         *
 ********************/
function elementVisible(isVisible: boolean) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      zoneId: props.slug,
      context: contexts.value,
    });
  }
}

function getVideoEmbedUrl(videoHost: string, videoId: string) {
  if (videoHost && videoId) {
    if (videoHost === 'vimeo') {
      return `https://player.vimeo.com/video/${videoId}?dnt=1`;
    }
    if (videoHost === 'youtube') {
      return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0`;
    }
  }
  return null;
}
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--video container my-12 pb-4"
  >
    <div
      v-observe-visibility="{
        callback: elementVisible,
        once: true,
        throttle: 500,
        intersection: {
          threshold: 0.5,
        },
      }"
      class="row justify-center"
    >
      <div
        v-if="props.fields.headline"
        class="col-12"
      >
        <h2
          class="m-0 mb-4 text-center"
          v-html="props.fields.headline"
        />
      </div>
      <div class="col-12 col-lg-9">
        <!-- see: https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari -->
        <div
          v-if="props.fields.video_host && props.fields.video_id"
          class="aspect-video rounded"
          style="-webkit-mask-image: -webkit-radial-gradient(white, black)"
        >
          <iframe
            :id="`player_${props.fields.video_id}`"
            :src="getVideoEmbedUrl(props.fields.video_host, props.fields.video_id)"
            loading="lazy"
            class="size-full"
            title="video"
            allowfullscreen
          />
        </div>
        <div
          v-if="props.fields.video_description"
          class="mt-1 text-center text-gray"
          v-html="props.fields.video_description"
        />
      </div>
    </div>
  </div>
</template>
